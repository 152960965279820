var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-sm-7"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filter,
      expression: "filter"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "id": ""
    },
    domProps: {
      "value": _vm.filter
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.searchItem.apply(null, arguments);
      },
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.filter = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "col-md-2"
  }, [_vm.filter.length > 3 ? _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.searchItem
    }
  }, [_vm._v("Search")]) : _vm._e()])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2",
    attrs: {
      "for": ""
    }
  }, [_vm._v(" Total Buku " + _vm._s(_vm.totalRows) + " ")]), _c('div', {
    staticClass: "col-sm-2"
  })]), _c('b-table', {
    attrs: {
      "sticky-header": "500px",
      "responsive": "sm",
      "show-empty": "",
      "busy": _vm.isLoading,
      "fields": _vm.fields,
      "hover": "",
      "items": _vm.items
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + (row.index + 1)) + " ")];
      }
    }, {
      key: "cell(approved)",
      fn: function (row) {
        return [!row.item.approved_md ? _c('button', {
          staticClass: "btn btn-sm btn-primary"
        }, [_c('i', {
          staticClass: "fa fa-check"
        })]) : _c('button', {
          staticClass: "btn btn-sm btn-success"
        }, [_vm._v(" Validated ")])];
      }
    }, {
      key: "cell(created)",
      fn: function (row) {
        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          attrs: {
            "title": _vm.formatDate(row.item.created)
          }
        }, [_vm._v(" " + _vm._s(_vm.formatTimeinfo(row.item.created)) + " ")])];
      }
    }, {
      key: "cell(updated)",
      fn: function (row) {
        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          attrs: {
            "title": _vm.formatDate(row.item.modified)
          }
        }, [_vm._v(" " + _vm._s(_vm.formatTimeinfo(row.item.modified)) + " ")])];
      }
    }])
  })], 1), _c('b-row', {
    staticClass: "justify-content-start ml-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "aria-controls": "my-table"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "col-sm-2",
    attrs: {
      "for": ""
    }
  }, [_vm._v(" Search Book "), _c('br')]);
}]

export { render, staticRenderFns }