<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="form-group row">
            <label for="" class="col-sm-2"> Search Book <br /> </label>
            <div class="col-sm-7">
              <input type="text" v-model="filter" name="" id="" class="form-control"  @keyup.enter="searchItem">
            </div>  
            <div class="col-md-2">
              <button class="btn btn-primary" v-if="filter.length >3" @click="searchItem">Search</button>
            </div>
          </div>
          <div class="form-group row">
            <label for="" class="col-sm-2"> Total Buku 
              {{ totalRows }}
            </label>
            <div class="col-sm-2">
            </div>  
          </div>
          <b-table 
            sticky-header="500px"
            responsive="sm"
            show-empty
            :busy="isLoading"
            :fields="fields"
            hover 
            :items="items">
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1)}}
            </template>
            <template #cell(approved)="row">
              <button v-if="!row.item.approved_md" class="btn btn-sm btn-primary">
                <i class="fa fa-check"></i>
              </button>
              <button v-else class="btn btn-sm btn-success">
                Validated
              </button>
            </template>
            <template #cell(created)="row">
              <div v-b-tooltip.hover :title="formatDate(row.item.created)">
              {{ formatTimeinfo(row.item.created) }}
              </div>
            </template>
            <template #cell(updated)="row">
              <div v-b-tooltip.hover :title="formatDate(row.item.modified)">
              {{ formatTimeinfo(row.item.modified) }}
              </div>
            </template>
          </b-table>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState,mapActions} from "vuex"
  export default {
    name:'RegisterItem',
    data() {
      return {
        currentPage: 1,
        perPage:100,
        fields:[
          { key:'No', label: 'No'},
          { key:'request_body',label:'Body'},
          { key:'created', label: 'Created', sortable: true},
          { key:'approved',label: 'Validasi Data'},
          { key:'updated', label: 'Updated'}
        ],
        filter: "",
      }
    },
    computed: {
			...mapState({
				isLoading: (state) => state.ax.isLoading,
				isError: (state) => state.ax.isError,
				totalRows: (state) => state.ax.totalRows,
				items: (state) => state.ax.items,
			}),
    },
    mounted() {
      this.actionFetch()
    },
		watch: {
			currentPage: function() {
				this.actionFetch();
			},
      $route: {
        immediate: true,
        handler(to) {
          document.title = to.meta.title || 'Items Request IDAX  | Content Management System Ebooks Gramedia.com';
        }
      },
		},
    methods: {
      ...mapActions("ax", ["fetchItemDraft",'search']),
      actionFetch() {
        let payload = {
          page: this.currentPage,
          limit: this.perPage
        }
        if (this.filter.length > 2 ) {
          payload.q = this.filter
        }
        this.fetchItemDraft(payload)
      },
      formatDate(date) {
        return moment(date).format("DD MMM YYYY hh:mm:ss")
      },
      isSingle(sku) {
        if (sku.substr(0,6)=='SC00PG') {
          return false
        }
        return true
      },
      formatTimeinfo(date) {
        return moment(date).fromNow()
      },
      searchItem() {
        this.search({q :this.filter})
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>